.App {
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color:#282c34;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.gradient {
  background-color: #282c34;
  background-image: linear-gradient(90deg,#1a2231 0%, #615d60 100%);
}
.gradientX {
  background-color: #282c34;
  background-image: linear-gradient(180deg,#7fff9d 0%, #695e68 100%);
}
.middle {
  align-content: center;
}
.center {
  display: flex;
  justify-content: 'center';
  align-items: 'center';
}
.gradient2 {
  background-color: #282c34;
  background-image: linear-gradient(180deg,#24252a 0%, #3e363b 100%);
}

* {
  /* Here we set the margin and padding 0 */
  margin: 0;
  
    padding: 0;
  }
  
  .navbar {
    display: flex;
  
  /* This is used to make the navbar sticky, So that the
    navbar stays at the top part even if we scroll */
    position: sticky;
    align-items: center;
    justify-content: center;
    top: 0px;
  
  /*it specifies the mouse cursor to be displayed
    when it is pointed over the element */
    cursor: pointer;
  }
  
  .nav-list {
    width: 50%;
    display: flex;
  }
  
  .nav-list li {
    list-style: none;
    padding: 26px 30px;
  }
  
  .nav-list li a {
    text-decoration: none;
    color: white;
  }
  
  .nav-list li a:hover {
    color: black;
  }
  
  .rightNav {
    width: 50%;
    text-align: right;
  }
  
  #search {
    padding: 5px;
    font-size: 17px;
    border: 2px solid grey;
    border-radius: 9px;
  }
  
  .background {
    background: rgba(0, 0, 0, 0.4) url(
);
    background-blend-mode: darken;
    background-size: cover;
  }
  